@font-face {
    font-family: 'chakra_petchbold';
    src: url('../assets/fonts/chakrapetch-bold-webfont.woff') format('woff2'),
         url('../assets/fonts/chakrapetch-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchbold_italic';
    src: url('../assets/fonts/chakrapetch-bolditalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchitalic';
    src: url('../assets/fonts/chakrapetch-italic-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchlight';
    src: url('../assets/fonts/chakrapetch-light-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchlight_italic';
    src: url('../assets/fonts/chakrapetch-lightitalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchmedium';
    src: url('../assets/fonts/chakrapetch-medium-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchmedium_italic';
    src: url('../assets/fonts/chakrapetch-mediumitalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchregular';
    src: url('../assets/fonts/chakrapetch-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchsemibold';
    src: url('../assets/fonts/chakrapetch-semibold-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchsemibold_italic';
    src: url('../assets/fonts/chakrapetch-semibolditalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-semibolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchbold';
    src: url('../assets/fonts/chakrapetch-bold-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'chakra_petchbold_italic';
    src: url('../assets/fonts/chakrapetch-bolditalic-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kumbh_sansbold';
    src: url('../assets/fonts/kumbhsans-bold-webfont.woff2') format('woff2'),
         url('../assets/fonts/kumbhsans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kumbh_sansextrabold';
    src: url('../assets/fonts/kumbhsans-extrabold-webfont.woff2') format('woff2'),
         url('../assets/fonts/kumbhsans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kumbh_sansextralight';
    src: url('../assets/fonts/kumbhsans-extralight-webfont.woff2') format('woff2'),
         url('../assets/fonts/kumbhsans-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kumbh_sansmedium';
    src: url('../assets/fonts/kumbhsans-medium-webfont.woff2') format('woff2'),
         url('../assets/fonts/kumbhsans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kumbh_sansregular';
    src: url('../assets/fonts/kumbhsans-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/chakrapetch-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }

    50% {
        background-position: 100% 100%;
    }

    100% {
        background-position: 0% 0%;
    }
}

.wave {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 12em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.wave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
}

.wave:nth-of-type(3) {
    bottom: -2.5em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
}

@keyframes wave {
    2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }
}

@keyframes type {
    0% {
        width: 0;
    }

    99.9% {
        border-right: .15em solid rgb(28, 12, 133);
    }

    100% {
        border: none;
    }
}

@-webkit-keyframes type {
    0% {
        width: 0;
    }

    99.9% {
        border-right: .15em solid rgb(28, 12, 133);
    }

    100% {
        border: none;
    }
}

@keyframes type2 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    99.9% {
        border-right: .15em solid rgb(28, 12, 133);
    }

    100% {
        opacity: 1;
        border: none;
    }
}

@-webkit-keyframes type2 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    99.9% {
        border-right: .15em solid rgb(28, 12, 133);
    }

    100% {
        opacity: 1;
        border: none;
    }
}

@keyframes type3 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes type3 {
    0% {
        width: 0;
    }

    1% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

@-webkit-keyframes blink {
    50% {
        border-color: tranparent;
    }
}

@keyframes moveAvatar {
    0% {
        transform: translate(14rem, -15rem);
    }
    15% {
        transform: translate(7rem, 0rem) scale(1.2, 0.5);
    }
    30% {
        transform: translate(2rem, -10rem) scale(1, 1);
    }
    45% {
        transform: translate(-2rem, 0rem) scale(1.2, 0.8);
    }
    60% {
        transform: translate(-5rem, -5.5rem) scale(1, 1);
    }
    75% {
        transform: translate(-10rem, 0rem) scale(1.1, 0.9);
    }
    100% {
        transform: translate(0rem, 0rem) scale(1, 1);
    }
}

@keyframes wave-animation {
    0%,
    100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(5deg);
    }
    75% {
        transform: rotate(-15deg);
    }
}

* {
    transition: all 0.3s ease-in-out;
}

body {
    margin: auto 0;
    overflow: auto;
    background: linear-gradient(315deg, rgba(180, 213, 255, 0.562) 3%, rgba(60, 133, 206, 0.5) 38%, rgb(255, 255, 255) 68%, rgba(220, 206, 255, 0.74) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
}

main {
    margin-top: 6rem;
    margin-bottom: 7rem;
}

nav>ul {
    display: flex;
    justify-content: flex-end;
}

nav>ul>li {
    padding: 1rem;
    list-style-type: none;
}

h1 {
    margin-top: 10vw;
    margin-left: 8vw;
    font-size: 3rem;
    font-family: 'chakra_petchbold';
    letter-spacing: 1.2rem;
    text-transform: uppercase;
}

h2{
    margin-top: 10vw;
    margin-left: 8vw;
    font-family:'chakra_petchmedium';
    letter-spacing: 0.8rem;
    text-transform: uppercase;
}

h3 {
    text-align: center;
    margin: 4vw;
    font-family: 'kumbh_sansbold';
}

h5{
    padding-top: 5vw;
    text-align: center;
    font-family: 'kumbh_sansregular';
}

p{
    font-family: 'kumbh_sansextralight';
}

section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5vw;
    margin-bottom: 10vw;
    font-family: 'kumbh_sansregular';
    font-size: 0.9rem;
    width: auto;
    text-align: center;
    padding:2rem;
}

.navbar-toggler{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.css-typing p {
    border-right: .15em solid rgb(28, 12, 133);
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.css-typing p:nth-child(1) {
    width: 70vw;
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    text-align: center;
}

.css-typing p:nth-child(2) {
    width: 70vw;
    opacity: 0;
    -webkit-animation: type2 2s steps(40, end);
    animation: type2 2s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    text-align: center;
}

.css-typing p:nth-child(3) {
    width: 70vw;
    opacity: 0;
    -webkit-animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
    animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.avatar {
    display: flex;
    margin-top: 16rem;
    justify-content: space-between;
    padding-right: 6vw;
    padding-left: 6vw;
}

.avatar > img {
    width: 25vw;
    position: relative;
    animation: moveAvatar 2s cubic-bezier(0.16, 1, 0.3, 1);
    animation-timing-function: ease-in;
}

nav {
    margin: 1rem;
    padding: 2rem;
    font-size: 1.2rem;
}

nav > ul > li > a {
    color: black;
    text-decoration: none;
    font-family: 'chakra_petchlight';
    letter-spacing: 0.3rem;
}

nav a:link{
    color:black;
}

nav a:visited{
    text-decoration: dotted;
}

nav a:hover{
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-family: 'chakra_petchlight_italic';
    text-shadow: .1em .1em 0 hsl(319, 80%, 75%); 
}

nav a:active{
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: larger;
    text-shadow: .1em .1em 0 hsl(319, 80%, 75%);
}

.bi-github{
    margin-left: 10px;
}

.eachIcon{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem;
    padding:2rem;
    width:20%;
    height:40%;
    font-family: chakra_petchregular;
    border-radius: 20px;
    background: linear-gradient(#cfc9e770, #e1f2f87b);
    opacity: 0.85;
    filter: alpha(opacity=85);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconFooter{
    margin:5px;
}

.iconFooter:hover{
    width:4vw;
    height:4vw;
}

.iconFooter:active {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

footer {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    font-family: 'chakra_petchlight';
    text-align: right;
    padding: 0.8vw;
    margin-bottom: 1vw;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: rgba(220, 206, 255, 0.322);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display:flex;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
}

footer > p {
    text-align: left;
    font-family: 'chakra_petchlight';
    letter-spacing: 0.3rem;
    color: rgb(80, 80, 80);
    font-size: 0.7rem;
    margin-bottom: 0;
}

footer > div{
    display:flex;
    align-content: center;
    align-items: center;
}

footer > div > p{
    font-size:50px;
}

footer > div > a{
    font-family: 'chakra_petchlight';
    letter-spacing: 0.3rem;
    color: rgb(80, 80, 80);
    text-decoration: none;
    font-size: 1rem;
}

footer div a:hover{
    color:black;
    font-weight: bold;
}

footer div p:hover{
    animation: wave-animation 0.3s infinite;
}

footer a:focus{
    color: black;
    font-size: larger;
    text-decoration: underline;
}

/*media queries*/
@media only screen and (min-width: 1400px) {
    .mobileSection{
        display:none;
    }
}

@media only screen and (max-width: 1399px) {
    .deskSection{
        display:none;
    }
    footer > div > p{
        font-size: 30px;
    }
    .eachIcon{
        padding: 20px;
        width: 15%;
    }
    .eachIcon > p{
        display:none;
    }
}

@media screen and (min-width: 451px) {
    #navMobil {
        display: none
    }
    .avatar{
        margin-top: 10vw;
        justify-content: space-around;
    }
    .mobileSection{
        margin-bottom:6vw;
    }
}

@media screen and (max-width: 660px){
    .iconFooter{
        height:35px;
        width: 35px;
    }
}

@media screen and (max-width: 450px){
    main{
        margin-bottom: 70px;
    }
    nav {
        display: none
    }
    .avatar{
        flex-direction: column;
        align-items: center;
        margin-top: 3vw;
    }
    .avatar img{
        animation: none;
        animation-timing-function: none;
        margin-right: none;
    }
    .avatar > h2{
        font-size: 1.2rem;
        letter-spacing: 0.5rem;
        text-align: center;
        width: 90%;
    }
    section{
        margin-top: 10vw;
        margin-bottom: 3vw;
    }
    section > h3{
        font-size: 1.1rem;
    }
    .deskSection{
        display:none
    }
}